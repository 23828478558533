import Link from "next/link";
import classNames from "classnames";

const TextLink = ({ disabled, text, href, className }) => (
  <Link href={disabled ? "#" : href}>
    <a
      className={classNames(
        "font-medium border-b border-gray-800 text-gray-800",
        className,
        {
          "text-gray-500": disabled,
        }
      )}>
      {text}
    </a>
  </Link>
);

export default TextLink;
