import classNames from "classnames";

const Container = ({ children, className, ...rest }) => (
  <div
    className={classNames("md:container md:px-0 mx-auto px-4", className)}
    {...rest}>
    {children}
  </div>
);

export default Container;
