import classNames from "classnames";
import { Popover } from "@geist-ui/react";

import styles from "./Popover.module.scss";

const Component = ({
  children,
  content,
  text,
  placement = "right",
  className,
  portalClassName,
  bgColor,
  ...props
}) => {
  const backgroundColor = bgColor?.replace("bg-", "").replace("-", "") || null;

  return (
    <div className={classNames("flex items-center", className)}>
      <Popover
        placement={placement}
        content={
          text ? (
            <span
              className={classNames("text-sm", {
                "text-white": backgroundColor,
                "text-gray-700": !backgroundColor,
              })}>
              {text}
            </span>
          ) : (
            content
          )
        }
        portalClassName={classNames(
          "max-w-250 ",
          portalClassName,
          styles.popover,
          {
            [styles[backgroundColor]]: backgroundColor,
          }
        )}
        {...props}>
        {children}
      </Popover>
    </div>
  );
};

Component.Item = (props) => <Popover.Item {...props} />;

export default Component;
