import { Modal } from "@geist-ui/react";
import classNames from "classnames";

const Component = ({
  className,
  children,
  open = true,
  preventClose = false,
  disabled,
  leftButtonText,
  leftButtonOnClick,
  rightButtonText,
  rightButtonOnClick,
  onCloseModal = () => {},
}) => (
  <Modal
    visible={open}
    wrapClassName={className}
    disableBackdropClick={preventClose || disabled}
    onClose={(event) => onCloseModal(event)}>
    {children}
    {leftButtonText && (
      <Modal.Action
        disabled={disabled}
        className="normal-case"
        onClick={leftButtonOnClick}>
        <p className="normal-case text-gray-800 text-sm">{leftButtonText}</p>
      </Modal.Action>
    )}
    {rightButtonText && (
      <Modal.Action
        disabled={disabled}
        className="normal-case"
        onClick={rightButtonOnClick}>
        <p className="normal-case text-gray-800 text-sm">{rightButtonText}</p>
      </Modal.Action>
    )}
  </Modal>
);

Component.Title = ({ children, className }) => (
  <Modal.Title>
    <p
      className={classNames(
        "text-base normal-case text-gray-800 font-semibold",
        className
      )}>
      {children}
    </p>
  </Modal.Title>
);

Component.Subtitle = ({ children, className }) => (
  <Modal.Subtitle className={className}>{children}</Modal.Subtitle>
);

Component.Content = ({ children, className }) => (
  <Modal.Content className={classNames("text-sm text-gray-800", className)}>
    {children}
  </Modal.Content>
);

export default Component;
