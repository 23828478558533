export const getAvatarLetters = ({ text }) => {
  const names = text.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const setPatientColor = () => {
  const colors = [
    "#A882DD",
    "#E08D79",
    "#49416D",
    "#6EA4BF",
    "#B6174B",
    "#569c5a",
    "#E76F51",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
};

export const getReadableGender = (gender) => {
  switch (gender) {
    case "male":
      return "Masculino";
    case "female":
      return "Feminino";
    case "other":
      return "Outro";
    default:
  }
};
