import Modal from "components/common/modal";
import Image from "next/image";

const Component = ({ preview, onCloseModal }) => {
  return (
    <Modal onCloseModal={onCloseModal}>
      <Modal.Content>
        <Image
          src={preview}
          className="object-scale-down bg-black"
          width="400"
          height="400"
        />
        <p className="mt-4 text-right">
          <a className="underline" target="_blank" href={preview}>
            Clique aqui
          </a>{" "}
          para abrir em tela cheia
        </p>
      </Modal.Content>
    </Modal>
  );
};

export default Component;
