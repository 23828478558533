import Container from "components/common/container";
import Link from "next/link";
import Image from "next/image";

const Footer = () => (
  <footer className="border-t border-gray-medium">
    <Container>
      <div className="py-6 flex flex-col lg:flex-row items-center">
        <div className="lg:mb-0 lg:pr-4 lg:w-1/2">
          <ul className="inline-flex">
            <li className="font-semibold">
              <Link href="mailto:contato@easynutri.app">
                <a className="flex">Fale conosco</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="mt-4 md:mt-0 flex justify-end items-center lg:pl-4 lg:w-1/2">
          <Link href="/">
            <a className="w-32 md:w-40 relative">
              <Image
                src="/images/logo.svg"
                layout="intrinsic"
                width={252}
                height={57}
                alt="Logotipo do Easynutri"
              />
            </a>
          </Link>
        </div>
      </div>
    </Container>
  </footer>
);

export default Footer;
