import { useMemo, forwardRef } from "react";
import classNames from "classnames";
import { Button } from "@geist-ui/react";
import {
  BiMessageSquareAdd,
  BiCalendarPlus,
  BiCalendarEdit,
} from "react-icons/bi";
import { RiUserAddLine, RiUserLine, RiCloseCircleLine } from "react-icons/ri";
import styles from "./Button.module.scss";

const Component = forwardRef(
  (
    {
      text,
      full,
      appearance,
      rounded,
      className,
      transparent,
      onlyIcon,
      icon,
      ...props
    },
    ref
  ) => {
    const renderIcon = () =>
      useMemo(() => {
        switch (appearance) {
          case "cancel":
            return <RiCloseCircleLine />;
          case "newPatient":
            return <RiUserAddLine />;
          case "patient":
          case "editPatient":
            return <RiUserLine />;
          case "newSchedule":
            return <BiCalendarPlus />;
          case "editSchedule":
            return <BiCalendarEdit />;
          case "newRecord":
            return <BiMessageSquareAdd />;
        }
      }, [appearance]);

    return (
      <div
        className={classNames(className, {
          "w-full flex": full,
        })}>
        <Button
          className={classNames([styles.button], [styles[appearance]], {
            "flex-1": full,
            border: appearance,
            [styles.transparent]: transparent,
            [styles.rounded]: rounded,
            [styles.onlyIcon]: onlyIcon,
          })}
          icon={icon || renderIcon()}
          ref={ref}
          {...props}>
          {text}
        </Button>
      </div>
    );
  }
);

export default Component;
