import Header from "components/header";
import Footer from "components/footer";
import Meta from "components/meta";
import Container from "components/common/container";

const Template = ({ children, hideMenu, hideAuthLinks }) => (
  <div>
    <Meta />
    <div className="min-h-screen">
      <Header hideMenu={hideMenu} hideAuthLinks={hideAuthLinks} />
      <Container style={{ minHeight: "calc(100vh - 217px - 1rem)" }}>
        <main className="mb-10">{children}</main>
      </Container>
    </div>
    <Footer />
  </div>
);

export default Template;
