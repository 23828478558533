import React, { useContext } from "react";
import { Divider } from "@geist-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import {
  BiCalendarMinus,
  BiLogOut,
  BiMessageSquareDetail,
} from "react-icons/bi";
import { RiUserLine, RiHome3Line } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";

import AppContext from "context/AppContext";
import Accordion from "components/common/accordion";

const Sidebar = () => {
  const router = useRouter();
  const { logout } = useContext(AppContext);

  const isActive = (path) => router.pathname.includes(path);

  const Item = ({ href, children, isActive }) => (
    <Link href={href}>
      <li
        className={classNames("text-sm cursor-pointer ml-6 py-1 pl-2 mt-1", {
          "bg-white rounded-md border border-gray-300": isActive,
        })}>
        {children}
      </li>
    </Link>
  );

  return (
    <div className="w-56">
      <Accordion
        open={isActive("records")}
        title="Atendimentos"
        icon={BiMessageSquareDetail}
        iconColor="text-green-500">
        <ul className="list-none mt-2">
          <Item href="/records/new" isActive={isActive("records/new")}>
            Novo atendimento
          </Item>
          <Item
            href="/records/list"
            isActive={isActive("records/list") || isActive("records/[id]")}>
            Meus atendimentos
          </Item>
        </ul>
      </Accordion>
      <Accordion
        open={isActive("patients")}
        title="Pacientes"
        icon={RiUserLine}
        iconColor="text-blue-500">
        <ul className="list-none mt-2">
          <Item href="/patients/new" isActive={isActive("patients/new")}>
            Novo paciente
          </Item>
          <Item
            href="/patients/list"
            isActive={isActive("patients/list") || isActive("patients/[id]")}>
            Meus pacientes
          </Item>
        </ul>
      </Accordion>
      <Accordion
        open={isActive("schedules")}
        title="Agenda"
        icon={BiCalendarMinus}
        iconColor="text-pink-600">
        <ul className="list-none mt-2">
          <Item href="/schedules/new" isActive={isActive("schedules/new")}>
            Novo agendamento
          </Item>
          <Item
            href="/schedules/list"
            isActive={isActive("schedules/list") || isActive("schedules/[id]")}>
            Meus agendamentos
          </Item>
        </ul>
      </Accordion>
      <Divider className="md:hidden" />
      <div className="md:hidden ">
        <Link href="/home">
          <p className="mt-4 items-center cursor-pointer flex">
            <RiHome3Line className="text-xl mr-2" /> Atendimentos
          </p>
        </Link>
        <Link href="/profile">
          <p className="mt-4 items-center cursor-pointer flex">
            <CgProfile className="text-xl mr-2" /> Meus Dados
          </p>
        </Link>
        <span
          className="mt-4 items-center cursor-pointer flex"
          onClick={() => logout()}>
          <BiLogOut className="text-xl mr-2" /> Sair
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
