import { useContext, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import className from "classnames";

import AppContext from "context/AppContext";
import Container from "components/common/container";
import TextLink from "components/common/link";
import Sidebar from "components/sidebar";

import UserMenu from "./UserMenu";

const Header = ({ hideMenu, hideAuthLinks }) => {
  const router = useRouter();
  const { isAuthenticated } = useContext(AppContext);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const signinRoute = "/signin";
  const registerRoute = "/register";
  const forgotPasswordRoute = "/forgot-password";
  const landingRoute = "/";

  const isSigninPage = router.pathname === signinRoute;
  const isRegisterPage = router.pathname === registerRoute;
  const isLandingRoute = router.pathname === landingRoute;
  const isForgotPasswordRoute = router.pathname === forgotPasswordRoute;

  return (
    <div className="bg-white flex items-center h-20 mb-4 md:mb-10 border-b border-gray-300">
      <Container className="flex flex-1 items-center justify-between">
        <div className="flex w-36 sm:w-40 relative">
          <Link href={isAuthenticated ? "/home" : "/"}>
            <a className="flex">
              <Image
                src="/images/logo.svg"
                layout="intrinsic"
                width={252}
                height={57}
                alt="Logotipo do Easynutri"
              />
            </a>
          </Link>
        </div>
        <div>
          {!isAuthenticated && !isSigninPage && !hideAuthLinks && (
            <TextLink href={signinRoute} text="Entrar" />
          )}
          {!isAuthenticated &&
            !isRegisterPage &&
            !hideAuthLinks &&
            !isLandingRoute &&
            !isForgotPasswordRoute && (
              <TextLink
                href={registerRoute}
                className="ml-4"
                text="Criar uma conta"
              />
            )}
          {isAuthenticated && !hideMenu && (
            <>
              <div className="flex">
                <UserMenu className="hidden md:flex" />
              </div>

              <div
                onClick={() => setOpenMobileMenu(true)}
                className="md:hidden">
                <Image
                  src="/icons/menu.svg"
                  width="20"
                  height="20"
                  alt="Icone ilustrativo do menu"
                />
              </div>
              <div
                onClick={() => setOpenMobileMenu(false)}
                className={className(
                  "z-10 fixed w-full h-screen left-0 top-0 transition duration-500",
                  {
                    "left-full": !openMobileMenu,
                    "bg-opacity-25 bg-black": openMobileMenu,
                  }
                )}
              />
              <div
                className={className(
                  "z-20 pt-6 fixed h-screen p-4 bg-gray-light right-0 top-0 transition-background duration-300",
                  {
                    "-right-full": !openMobileMenu,
                  }
                )}>
                <Sidebar />
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
