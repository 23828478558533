import { useState } from "react";
import classNames from "classnames";
import { FaSearch } from "react-icons/fa";

import styles from "./Avatar.module.scss";
import AttachmentModal from "components/patients/modals/attachmentPreview";

const Avatar = ({ color, large, extraLarge, className, text, image }) => {
  const [preview, setPreview] = useState();
  const [showZoomIcon, setShowZoomIcon] = useState();
  const formattedImage =
    image &&
    `https://res.cloudinary.com/robsoncoelho/image/upload/c_scale,w_500/c_crop,g_face,h_400,w_400/${image?.provider_metadata?.public_id}.jpg`;

  return (
    <div
      style={{ backgroundColor: !image && color }}
      className={classNames(
        "border relative overflow-hidden rounded-full flex justify-center items-center",
        [styles.avatar],
        className,
        {
          "cursor-pointer": image,
          "border-none": !extraLarge,
          "border-gray-medium": extraLarge,
          [styles.large]: large,
          [styles.extraLarge]: extraLarge,
        }
      )}>
      {image ? (
        <div
          onMouseEnter={() => extraLarge && setShowZoomIcon(true)}
          onMouseLeave={() => extraLarge && setShowZoomIcon(false)}
          onClick={() => setPreview(formattedImage)}
          className="relative h-full w-full flex justify-center items-center">
          <img className="object-cover" layout="fill" src={formattedImage} />
          <>
            <div
              className={classNames(
                "transition duration-200 opacity-0 h-full w-full bg-opacity-50 bg-gray-800 absolute",
                {
                  "opacity-100": showZoomIcon,
                }
              )}
            />
            <FaSearch
              className={classNames(
                "transition duration-200 opacity-0 z-9 text-2xl relative text-white",
                {
                  "opacity-100": showZoomIcon,
                }
              )}
            />
          </>
        </div>
      ) : (
        <p
          className={classNames("uppercase border-none text-white", {
            "text-xs": !large,
            "text-xs md:text-sm": large,
            "text-sm md:text-base": extraLarge,
          })}>
          {text}
        </p>
      )}
      {preview && (
        <AttachmentModal
          onCloseModal={() => setPreview(null)}
          preview={preview}
        />
      )}
    </div>
  );
};

export default Avatar;
